<script>
import { useMainStore, useEventStore } from '../../../stores/index';
import NewChat from './NewChat.vue';
import ChatMensagem from './ChatMensagens.vue';
import ChatSpoiler from './ChatSpoiler.vue';
import NewChatGroup from './NewChatGroup.vue';
import { AxiosAPI } from '@/axios';
export default {
  name: 'ChatDocs',
  components: {
    NewChat,
    ChatMensagem,
    ChatSpoiler,
    NewChatGroup
  },
  setup () {
    const StoreAll = useMainStore();
    const event = useEventStore();
    return {
      Store: StoreAll,
      event
    };
  },
  data () {
    return {
      toggle: false,
      newChat: false,
      newChatGroup: false,
      chat: false,
      chatnow: false,
      chatnowDataUser: [],
      mensagens: [],
      usersChat: [],
      countNotify: 0
    };
  },
  watch: {
    'Store.chats': function (newValue, oldPath) {
      let counters = 0;
      this.Store.chats.forEach(counter => {
        counters = counters + counter.notifications_count;
      });
      this.countNotify = counters;
    }
  },
  mounted () {
    const getCounter = () => {
      if (this.Store.chats !== null) {
        clearInterval(checkCounter);
        let counters = 0;
        this.Store.chats.forEach(counter => {
          counters = counters + counter.notifications_count;
        });
        this.countNotify = counters;
      }
    };
    const checkCounter = setInterval(getCounter, 2000);
  },
  methods: {
    toggleTask () {
      if (!this.toggle) {
        this.toggle = true;
        this.chat = true;
        this.newChatGroup = false;
        this.newChat = false;
        this.chatnow = false;
      } else if (this.toggle) {
        this.toggle = false;
        this.newChat = false;
        this.newChatGroup = false;
        this.chat = false;
        this.chatnow = false;
      }
    },

    toggleNewChat () {
      this.newChat = true;
      this.newChatGroup = false;
      this.chat = false;
      this.chatnow = false;
    },

    toggleNewChatGroup () {
      this.newChat = false;
      this.newChatGroup = true;
      this.chat = false;
      this.chatnow = false;
    },

    toggleBackNewChat () {
      this.newChat = false;
      this.newChatGroup = false;
      this.chat = true;
      this.chatnow = false;
    },

    toggleBackMensage () {
      this.newChat = false;
      this.newChatGroup = false;
      this.chat = true;
      this.chatnow = false;
    },

    handleStartChat (userData) {
      let chatCall = [];
      this.Store.chats.forEach(getChats => {
        if (getChats.type === 'private') {
          getChats.users.forEach(UsersChat => {
            if (UsersChat.uuid === userData.uuid) {
              chatCall = getChats;
            }
          });
        }
      });
      if (chatCall.length <= 0) {
        chatCall = { newchat: true, ...userData };
        this.chatnowDataUser = chatCall;
      } else {
        chatCall = { newchat: false, ...chatCall };
        this.chatnowDataUser = chatCall;
      }
      this.chat = false;
      this.chatnow = true;
      this.newChat = false;
      this.newChatGroup = false;
    },

    handleStartChatGroup (userData) {
      const usersUUID = [];
      userData[1].forEach(user => {
        usersUUID.push(user.uuid);
      });
      AxiosAPI.post('/api/chats',
        {
          users: usersUUID,
          type: 'group',
          title: userData[0].tittle
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + this.Store.accessToken
          }
        })
        .then((response) => {
          let chatCall = [];
          chatCall = { newchat: false, ...response.data.chat };
          this.chatnowDataUser = chatCall;
          this.chat = false;
          this.chatnow = true;
          this.newChat = false;
          this.newChatGroup = false;
        });
    },

    handleIntoChat (userData) {
      this.chatnowDataUser = userData;
      this.chat = false;
      this.chatnow = true;
      this.newChat = false;
      this.newChatGroup = false;
    }
  }
};
</script>
<style scoped>
.chat-box {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 500px;
  height: 700px;
  border: 1px solid #F1860B;
  background-color:#fff;
  border-radius: 20px 20px 0 0;
  z-index: 1000;
}
.chat-new{
    width: 170px !important;
    height: 50px !important;
    border: 1px solid #F1860B;
    border-radius: 10px !important;
    z-index: 1000;
    font-size: 18px !important;
    color: #fff !important;
}
.chat-new-group {
    width: 170px !important;
    height: 50px !important;
    border: 1px solid #F1860B;
    border-radius: 10px !important;
    z-index: 1000;
    font-size: 18px !important;
    color: #fff !important;
    bottom: 85px !important;
}
.chat-back{
  right: 500px !important;
  border-radius: 20px 0 0 20px !important;
  bottom:  0px !important;
  margin: 0 !important;
}
.chat-text{
  width: 500px !important;
  border-radius: 0 0 0 0 !important;
  bottom:  0px !important;
  right: 0px !important;
  cursor: text !important;
}
.chat-scroll {
  overflow: overlay;
  height: 650px;
  overflow-x: hidden;
}

@media screen and (max-width: 1366px) {
  .chat-box {
  height: 600px;
}
.chat-scroll {
    overflow: overlay;
    height: 545px;
    overflow-x: hidden;
}
}
</style>
<template>
    <div class="fixed-plugin">
      <a v-if="!toggle"
        class="px-3 py-2 fixed-plugin-button text-dark position-fixed background-Docs"
        @click="toggleTask()"
      >
        <i v-if="countNotify <= 0" class="fa fa-comment" style="color: white;" aria-hidden="true"></i>
        <i v-if="countNotify >= 1" class="fa fa-comment" style="color: yellow; position: relative;" aria-hidden="true">
          <span style="position: absolute; top: -10px; right: -10px; background: red; color: white; border-radius: 50%; padding: 2px 6px; font-size: 12px;">
            {{ countNotify }}
          </span>
        </i>
      </a>
        <div v-if="toggle" class="chat-box">
            <div class="background-Docs card-header d-flex justify-content-between align-items-center">
                <div>
                    <div><span class="font-docs"><b>Conversas</b></span></div>
                </div>
                <button type="button" class="close" @click="toggleTask()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="card-body" style="padding: 0rem 0rem !important;">
              <div v-if="chatnow && !newChat && !newChatGroup && !chat">
                    <ChatMensagem :user="chatnowDataUser"/>
                </div>

                <div v-if="newChat && !newChatGroup && !chat">
                    <NewChat @start-chat="handleStartChat"/>
                </div>

                <div v-if="newChatGroup && !newChat && !chat">
                  <NewChatGroup @start-chat-group="handleStartChatGroup"/>
                </div>

                <div v-if="chat && !newChat && !newChatGroup">
                    <ChatSpoiler @IntoChat="handleIntoChat"/>
                </div>

                <a v-if="toggle && !newChatGroup && !newChat && !chatnow"  @click="toggleNewChat()"
                    class="px-3 py-2 fixed-plugin-button text-dark position-fixed background-Docs chat-new"
                >
                    <i class="fa fa-plus mr-2" style="color: white;" aria-hidden="true"></i>
                    <span class="font-docs">Nova conversa</span>
                </a>
                <a v-if="toggle && !newChatGroup && !newChat && !chatnow"  @click="toggleNewChatGroup()"
                    class="px-3 py-2 fixed-plugin-button text-dark position-fixed background-Docs chat-new-group"
                >
                    <i class="fa fa-plus mr-2" style="color: white;" aria-hidden="true"></i>
                    <span class="font-docs">Novo grupo</span>
                </a>
                <a v-if="toggle && newChat && !chatnow || toggle && newChatGroup && !chatnow"  @click="toggleBackNewChat()"
                    class="px-3 py-2 fixed-plugin-button text-dark position-fixed background-Docs"
                >
                    <i class="fa fa-arrow-left" style="color: white;" aria-hidden="true"></i>
                    <span class="font-docs"></span>
                </a>
                <a v-if="toggle && chatnow"  @click="toggleBackMensage()"
                    class="px-3 py-2 fixed-plugin-button text-dark position-fixed background-Docs chat-back"
                >
                    <i class="fa fa-arrow-left" style="color: white;" aria-hidden="true"></i>
                    <span class="font-docs"></span>
                </a>
            </div>
        </div>
    </div>
  </template>
