/* eslint-disable no-useless-return */
import { defineStore } from 'pinia';
import { AxiosAPI } from '@/axios';
import Cookie from 'js-cookie';

export const useMainStore = defineStore('main', {
  state: () => ({
    accessToken: null,
    host: 'https://smart2docs-dev.s3.amazonaws.com/',
    doc_link: '',
    mobile: false,
    notification: false,
    notifyReceived: false,
    users: [],
    user: [],
    role: null,
    document: {},
    Departamentos: {},
    DepartamentosAdd: [],
    departamentsAll: [],
    abilities: [],
    DocumentResp: [],
    viewDispatch: 0,
    DataUsers: [],
    /* framework */
    hideConfigButton: true,
    showConfig: true,
    isPinned: true,
    Menu: false,
    progress: 1,
    SecondSend: false,
    tableKeys: [],
    FirstIndividuallySend: [],
    SecondIndividuallySend: [],
    tableNoFormat: [],
    documentData: { typeDoc: 0, attachments: [], signerAttach: null, subject: '', body: '', status_sign: 0, user_id: 0, department_id: 0, to_users: [], to_departments: [] },
    SignersData: [],
    SendToData: { },
    SendTwo: false,
    Types: [],
    pdf64: '',
    SuccessDepatch: false,
    DocSend: 0,
    DocRecep: 0,
    DocSign: 0,
    DocLessSign: 0,
    DocLessSignTrue: 0,
    NotViewed: 0,
    NotViewedTrue: 0,
    pdfNow: '',
    notifyMessages: [],
    is_new_document: true,
    draftID: 0,
    DocumentDespachComplete: [],
    blockBySigner: false,
    EditDocumentSolo: false,
    EditDocumentGroup: false,
    progressEdit: 1,
    SecondSendEdit: false,
    tableKeysEdit: [],
    FirstIndividuallySendEdit: [],
    SecondIndividuallySendEdit: [],
    tableNoFormatEdit: [],
    documentDataEdit: { typeDoc: 0, attachments: [], attachments_old: [], attachments_new: [], subject: '', body: '', status_sign: 0, user_id: 0, department_id: 0 },
    signerAttach: null,
    editDocAttach: false,
    SignersDataEdit: [],
    SendToDataEdit: { },
    SendTwoEdit: false,
    EditDocumentSigners: false,
    DocumentEditID: 0,
    TypesEdit: [],
    EditDocArray: [],
    editDocumentSucess: false,
    routesNow: '',
    ListUsersDepartament: [],
    chats: null
  }),

  actions: {

    toggleConfigurator () {
      this.showConfig = !this.showConfig;
    },

    Login (value) {
      return new Promise((resolve, reject) => {
        AxiosAPI
          .post('/api/auth/tokens/create', {
            email: value.email,
            password: value.password,
            token_name: 'dev'
          })
          .then(resp => {
            const token = resp.data.token;
            Cookie.set('User', token, { expires: 1 });
            this.accessToken = token;
            resolve(resp);
          })
          .catch(err => {
            reject(err);
          });
      });
    },

    Token () {
      if (Cookie.get('User') == null && this.accessToken == null) {
        return;
      } else if (Cookie.get('User') != null && this.accessToken == null) {
        this.accessToken = Cookie.get('User');
      } else {
        return;
      }
    },

    async GetNumbers () {
      const uniqueNotifyData = [];
      const ids = new Set();
      const notifyData = [];
      const today = new Date();
      const thirtyDaysAgo = new Date();
      thirtyDaysAgo.setDate(today.getDate() - 365);

      const options = { year: 'numeric', month: '2-digit', day: '2-digit' };

      const todayFormatted = today.toLocaleDateString('en-US', options);
      const thirtyDaysAgoFormatted = thirtyDaysAgo.toLocaleDateString('en-US', options);

      await AxiosAPI
        .get('/api/documents/activity',
          {
            params: {
              date_start: thirtyDaysAgoFormatted,
              date_end: todayFormatted
            },
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + this.accessToken
            }
          }).then((response) => {
          this.NotViewed = response.data.documents.not_viewed;
          this.DocSend = response.data.documents.sents;
          this.DocRecep = response.data.documents.receiveds;
          this.DocSign = response.data.documents.signeds;
          this.DocLessSign = response.data.documents.to_sign;
        });

      await AxiosAPI
        .get('api/documents/received/not-viewed', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + this.accessToken
          }
        }).then((response) => {
          console.log(response.data.documents.data.length);
          this.NotViewedTrue = response.data.documents.data.length;
          response.data.documents.data.forEach(documents => {
            if (documents.signers.length >= 1) {
              documents.signers.forEach(signer => {
                if (signer.user_id === this.user.id && signer.clicksign_status === '0') {
                  documents.tosigner = true;
                  notifyData.push(documents);
                }
                if (signer.user_id !== this.user.id) {
                  notifyData.push(documents);
                }
              });
            }
            if (documents.signers.length <= 0) {
              notifyData.push(documents);
            }
          });
        });

      await AxiosAPI
        .get('/api/documents/departments/not-viewed', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + this.accessToken
          }
        }).then((response) => {
          console.log(response);
          this.NotViewedTrue = this.NotViewedTrue + response.data.documents.data.length;
          response.data.documents.data.forEach(documents => {
            if (documents.signers.length >= 1) {
              documents.signers.forEach(signer => {
                if (signer.user_id === this.user.id && signer.clicksign_status === '0') {
                  documents.tosigner = true;
                  notifyData.push(documents);
                }
                if (signer.user_id !== this.user.id) {
                  notifyData.push(documents);
                }
              });
            }
            if (documents.signers.length <= 0) {
              notifyData.push(documents);
            }
          });
        });

      await AxiosAPI
        .get('api/documents/signature-requirement?page=1&status=pending', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + this.accessToken
          }
        }).then((response) => {
          this.DocLessSignTrue = response.data.documents.data.length;
          response.data.documents.data.forEach(documents => {
            if (documents.signers.length >= 1) {
              documents.signers.forEach(signer => {
                if (signer.user_id === this.user.id && signer.clicksign_status === '0') {
                  documents.tosigner = true;
                  notifyData.push(documents);
                }
                if (signer.user_id !== this.user.id) {
                  notifyData.push(documents);
                }
              });
            }

            if (documents.signers.length <= 0) {
              notifyData.push(documents);
            }
          });
        });

      await AxiosAPI
        .get('api/documents/signature-requirement?page=1', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + this.accessToken
          }
        }).then((response) => {
          response.data.documents.data.forEach(documents => {
            if (documents.attachments_sign && documents.attachment_sign_status === 0) {
              documents.tosignerAttach = true;
              notifyData.push(documents);
            }
          });
        });
      notifyData.forEach(item => {
        if (!ids.has(item.id)) {
          ids.add(item.id);
          uniqueNotifyData.push(item);
        }
      });
      uniqueNotifyData.sort((a, b) => a.id - b.id);
      this.notifyMessages = uniqueNotifyData;
    },

    DepartamentosAll () {
      AxiosAPI.get('/api/departments/list',
        {
          headers: {
            Authorization: 'Bearer ' + this.accessToken
          }
        })
        .then((resp) => {
          this.departamentsAll = resp.data.departments;
        });
    },

    Get_departaments () {
      AxiosAPI.get('/api/departments/',
        {
          headers: {
            Authorization: 'Bearer ' + this.accessToken
          }
        })
        .then((resp) => {
          this.Departamentos = resp.data.departments;
        });
    },

    Get_abilities () {
      AxiosAPI.get('/api/abilities/',
        {
          headers: {
            Authorization: 'Bearer ' + this.accessToken
          }
        })
        .then((resp) => {
          this.abilities = resp.data.abilities;
        });
    },

    async Get_users () {
      await AxiosAPI.get('/api/users/',
        {
          headers: {
            Authorization: 'Bearer ' + this.accessToken
          }
        })
        .then((resp) => {
          this.users = resp.data.users;
        });
    },

    async Get_user () {
      await AxiosAPI.get('/api/auth/my-user-information',
        {
          headers: {
            Authorization: 'Bearer ' + this.accessToken
          }
        })
        .then((resp) => {
          let UserData = [];
          UserData = resp.data.user;
          UserData.profile_image = this.host + resp.data.user.path_image;
          this.user = UserData;
          this.role = resp.data.user.abilities[0].id;
        });
    },

    async GetTypes () {
      return new Promise((resolve, reject) => {
        AxiosAPI.get('/api/document_types/', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + this.accessToken
          }
        })
          .then((resp) => {
            this.Types = resp.data.types;
            resolve(resp.data.types);
          })
          .catch(err => {
            reject(err);
          });
      });
    },

    SubmitDoc (payload) {
      return new Promise((resolve, reject) => {
        const formData = new FormData();
        const coauthors = payload.coauthors;
        const coautorJSON = JSON.stringify(coauthors);

        formData.append('name', payload.name);
        formData.append('attachment', payload.attachment);
        formData.append('document_category_id', payload.document_category_id);
        formData.append('document_status_vote_id', payload.document_status_vote_id);
        formData.append('document_status_movement_id', payload.document_status_move_id);
        formData.append('user_id', payload.user_id);
        formData.append('status_sign', payload.status_sign);
        formData.append('coauthors', coautorJSON);

        AxiosAPI
          .post('/documents', formData, {
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + this.accessToken
            }
          }).then((response) => {
            this.doctrue = response;
            resolve(response);
          })
          .catch(err => {
            reject(err);
          });
      });
    },

    Notify () {
      AxiosAPI
        .get('api/documents/received/not-viewed', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + this.accessToken
          }
        }).then(() => {
        });
    },

    ChatGet () {
      AxiosAPI
        .get('api/chats', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + this.accessToken
          }
        }).then((resp) => {
          console.log(resp);
          this.chats = resp.data.chats;
        });
    },

    /*     ChatMensagens () {
      AxiosAPI
        .get('api/chats/messages', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + this.accessToken
          }
        }).then((resp) => {
          console.log(resp);
        });
    }, */

    async Notify_received () {
      await AxiosAPI
        .get('/api/documents/received', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + this.accessToken
          }
        }).then((response) => {
          const SignersBlock = [];
          const DocSignersRequired = [];
          const NoSignerRequired = [];
          const DocSignersRequiredOff = [];

          response.data.documents.data.forEach(doc => {
            doc.signers.forEach(signer => {
              if (signer.required === true) {
                SignersBlock.push(signer);
                if (signer.clicksign_status === '0') {
                  if (!DocSignersRequired.some(existingDoc => existingDoc.id === doc.id)) {
                    DocSignersRequired.push(doc);
                  }
                } else {
                  if (!DocSignersRequiredOff.some(existingDoc => existingDoc.id === doc.id)) {
                    DocSignersRequiredOff.push(doc);
                  }
                }
              } else {
                if (!NoSignerRequired.some(existingDoc => existingDoc.id === doc.id)) {
                  NoSignerRequired.push(doc);
                }
              }
            });
          });

          if (DocSignersRequired.length >= 1) {
            const blockDocumentIds = new Set(DocSignersRequired.map(signer => signer.id));
            this.DocumentResp = NoSignerRequired.filter(doc => !blockDocumentIds.has(doc.id));
          } else if (DocSignersRequiredOff.length >= 1) {
            this.DocumentResp = DocSignersRequiredOff;
          } else {
            this.DocumentResp = NoSignerRequired;
          }
          this.DocumentResp.forEach(view => {
            if (view.viewed === false) {
              this.notifyReceived = true;
            } else {
              this.notifyReceived = false;
            }
          });
        });
    }

  }

});

export const useResponseStore = defineStore('responseDoc', {
  state: () => ({
    progress: 1,
    oldDoc: [],
    oldDocProtocol: '',
    SecondSend: false,
    tableKeys: [],
    FirstIndividuallySend: [],
    SecondIndividuallySend: [],
    tableNoFormat: [],
    documentData: { document_id: 0, typeDoc: 0, signerAttach: null, attachments: [], subject: '', body: '', status_sign: 0, user_id: 0, department_id: 0, to_users: [], to_departments: [] },
    SendToData: { },
    SignersData: [],
    SendTwo: false,
    Types: [],
    pdf64: ''
  })

});

export const useEventStore = defineStore('event', {
  state: () => ({
    CreatHall: null,
    EditHall: null,
    SignerConfirm: null,
    Response: null,
    SendDoc: null,
    SendDocument: null
  })
});
