<script>
import { useMainStore, useEventStore } from '../../../stores/index';
import moment from 'moment';
import { AxiosAPI } from '@/axios';
export default {
  name: 'ChatMensagem',
  props: {
    user: {
      type: Object,
      required: true
    }
  },
  setup () {
    const StoreAll = useMainStore();
    const event = useEventStore();
    return {
      Store: StoreAll,
      event
    };
  },
  data () {
    return {
      chatnowDataUser: [],
      mensagens: [],
      ToSendUser: [],
      mensagemToSend: '',
      uuid: '',
      newchat: false,
      userScrolled: false
    };
  },
  updated () {
    if (!this.userScrolled) {
      this.scrollToBottom();
    }
  },
  mounted () {
    if (this.user.title !== undefined) {
      this.uuid = this.user.uuid;
      this.user.users.forEach(DataUser => {
        if (DataUser.uuid !== this.Store.user.uuid) {
          this.ToSendUser = DataUser;
        }
      });
      this.getMensagens();
      this.newchat = this.user.newchat;
    }
    if (this.user.title === undefined) {
      this.uuid = this.user.uuid;
      this.ToSendUser = this.user;
      this.newchat = this.user.newchat;
    }

    window.Echo.channel('chat.' + this.uuid).listen('.App\\Events\\MessageEvent', (data) => {
      const msg = this.mensagens;
      msg.push(data.message);
      this.mensagens = msg;
    });

    const container = this.$refs.chatContainer;

    this.handleScroll = () => {
      const threshold = 100;
      const position = container.scrollTop + container.offsetHeight;
      const height = container.scrollHeight;

      if (position >= height - threshold) {
        this.userScrolled = false;
      } else {
        this.userScrolled = true;
      }
    };

    container.addEventListener('scroll', this.handleScroll);
  },

  beforeUnmount () {
    const container = this.$refs.chatContainer;
    if (this.handleScroll) {
      container.removeEventListener('scroll', this.handleScroll);
    }
  },
  methods: {
    SendMensagem () {
      if (this.newchat) {
        AxiosAPI.post('/api/chats',
          {
            users: [this.user.uuid],
            type: 'private'
          },
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + this.Store.accessToken
            }
          })
          .then((response) => {
            AxiosAPI.post('/api/chats/send-message',
              {
                chat_uuid: response.data.chat.uuid,
                content: this.mensagemToSend
              },
              {
                headers: {
                  'Content-Type': 'application/json',
                  Authorization: 'Bearer ' + this.Store.accessToken
                }
              })
              .then((response) => {
                this.getMensagensNewChat(response.data.message.chat_uuid);
                this.mensagemToSend = '';
                this.Store.ChatGet();
                window.Echo.channel('chat.' + response.data.message.chat_uuid).listen('.App\\Events\\MessageEvent', (data) => {
                  const msg = this.mensagens;
                  msg.push(data.message);
                  this.mensagens = msg;
                });
                this.newchat = false;
                this.uuid = response.data.message.chat_uuid;
              });
          });
      }
      if (!this.newchat) {
        AxiosAPI.post('/api/chats/send-message',
          {
            chat_uuid: this.uuid,
            content: this.mensagemToSend
          },
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + this.Store.accessToken
            }
          })
          .then((response) => {
            this.mensagemToSend = '';
            this.Store.ChatGet();
          });
      }
    },
    getMensagens () {
      AxiosAPI.get('/api/chats/messages?chat_uuid=' + this.uuid, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.Store.accessToken
        }
      })
        .then((response) => {
          this.mensagens = response.data.chats.messages;
          AxiosAPI.post('/api/chats/read-notifications',
            {
              chat_uuid: this.uuid
            },
            {
              headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + this.Store.accessToken
              }
            })
            .then((response) => {
              console.log(response);
            });
        });
    },

    getMensagensNewChat (value) {
      AxiosAPI.get('/api/chats/messages?chat_uuid=' + value, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.Store.accessToken
        }
      })
        .then((response) => {
          this.mensagens = response.data.chats.messages;
        });
    },

    scrollToBottom () {
      const container = this.$refs.chatContainer;
      container.scrollTop = container.scrollHeight;
    },

    formatTimer (value) {
      moment.locale('pt-br');
      return moment(value).format('HH:mm');
    }
  }
};
</script>
<style scoped>
.chat-header{
  background-color: #3c3d3b;
  color: white;
  padding: 5px;
  margin: 0 5px 5px 0;
  width: 500px;
}
.chat-text{
  width: 450px !important;
  border-radius: 0 0 0 0 !important;
  bottom:  0px !important;
  right: 50px !important;
  cursor: text !important;
  height: 47px;
}
.chat-text-send {
  border-radius: 0 0 0 0 !important;
  bottom:  0px !important;
  right: 0px !important;
  height: 47px;
}
.chat-box-msg-owner{
  background-color: #F1860B;
  color: white;
  border-radius: 10px;
  padding: 5px;
  margin: 5px;
  right: 0px;
  width: 100%;
  min-width: 65px !important;
  max-width: 225px !important;
}

.chat-box-msg-recived{
  background-color: #515050;
  color: white;
  border-radius: 10px;
  padding: 5px;
  margin: 5px;
  right: 0px;
  width: 225px;
}

.MensageChat {
    overflow: overlay;
    height: 540px;
}
@media screen and (max-width: 1366px) {
  .MensageChat {
    overflow: overlay;
    height: 445px;
}
}
</style>
<template>
    <div>
        <div class="d-flex justify-content-between align-items-center chat-header">
          <div v-if="this.user.type ===  'group'">
            <img src="../../../assets/img/group.png" style="width: 40px; height: 40px; border-radius: 50%;">
            <span class="font-docs ml-2" style="font-size:18px">{{ this.user.title }}</span>
          </div>
          <div v-if="this.user.type !==  'group'">
            <img :src="Store.host + ToSendUser.path_image" style="width: 40px; height: 40px; border-radius: 50%;">
            <span class="font-docs ml-2" style="font-size:18px">{{ ToSendUser.name }}</span>
          </div>
          <div>

          </div>
        </div>

    <div ref="chatContainer" class="MensageChat" style="padding: 1rem 1rem;">
        <div v-if="this.user.type === 'group'">
          <div v-for="msg in mensagens" :key="msg.id">
            <div v-if="msg.user.uuid === Store.user.uuid">
                <div class="d-grid justify-content-end">
                  <span class="font-docs text-end mr-2" style="font-size:18px">{{ msg.user.name }}</span>
                    <div class="d-flex flex-column justify-content-end chat-box-msg-owner">
                      <span class="font-docs text-start">{{ msg.content }}</span>
                      <span class="font-docs text-muted text-end" style="font-size: 14px; color:#6e3d3d!important">{{ formatTimer(msg.created_at) }}</span>
                    </div>
                </div>
               </div>

               <div v-if="msg.user.uuid !== Store.user.uuid">
                <div class="d-grid justify-content-start">
                  <span class="font-docs text-start ml-2" style="font-size:18px">{{ msg.user.name }}</span>
                    <div class="d-flex flex-column justify-content-start chat-box-msg-recived">
                      <span class="font-docs text-start">{{ msg.content }}</span>
                    <span class="font-docs text-muted text-start" style="font-size: 14px; color:#c7c7c7!important">{{ formatTimer(msg.created_at) }}</span>
                    </div>
                </div>
               </div>
           </div>
          </div>

        <div v-if="this.user.type !==  'group'">
          <div v-for="msg in mensagens" :key="msg.id">
              <div v-if="msg.user.uuid === Store.user.uuid" class="d-flex justify-content-end">
                <div v-if="msg.user.uuid === Store.user.uuid" class="d-flex justify-content-end">
                  <div class="d-flex flex-column justify-content-end chat-box-msg-owner">
                    <span class="font-docs text-start">{{ msg.content }}</span>
                    <span class="font-docs text-muted text-end" style="font-size: 14px; color:#6e3d3d!important">{{ formatTimer(msg.created_at) }}</span>
                  </div>
                </div>
               </div>
               <div v-if="msg.user.uuid !== Store.user.uuid" class="d-flex justify-content-start">
                  <div class="d-flex flex-column justify-content-start chat-box-msg-recived">
                    <span class="font-docs text-start">{{ msg.content }}</span>
                    <span class="font-docs text-muted text-start" style="font-size: 14px; color:#c7c7c7!important">{{ formatTimer(msg.created_at) }}</span>
                  </div>
               </div>
           </div>
          </div>
        </div>
        <div>
            <textarea class="fixed-plugin-button text-dark position-fixed chat-text" style="resize: none;" rows="2" cols="" v-model="mensagemToSend"></textarea>
            <a @click="SendMensagem()"
                    class="px-3 py-2 fixed-plugin-button text-dark position-fixed background-Docs chat-text-send"
                >
                    <i class="fa fa-arrow-right" style="color: white;" aria-hidden="true"></i>
                    <span class="font-docs"></span>
                </a>
        </div>
    </div>
</template>
