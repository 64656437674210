<script>
import { useMainStore, useEventStore } from '../../../stores/index';
import moment from 'moment';
export default {
  name: 'ChatSpoiler',
  setup () {
    const StoreAll = useMainStore();
    const event = useEventStore();
    return {
      Store: StoreAll,
      event
    };
  },
  data () {
    return {
      mensagens: []
    };
  },
  methods: {
    IntoChat (value) {
      this.$emit('IntoChat', value);
    },

    formatTimer (value) {
      moment.locale('pt-br');
      return moment(value).format('HH:mm');
    }
  }
};
</script>
<style>
.chat-spoiler {
  width: 100%;
  height: 100%;
  background-color: #f5f5f5;
  border-radius: 10px;
  padding: 10px;
  overflow-y: auto;
}
</style>
<template>
    <div class="chat-scroll">
        <div v-if="Store.chats.length < 1" class="d-flex justify-content-center mt-3">
          <span class="font-docs" style="font-size: 18px">Nenhum mensagem ainda, inicie uma conversa!</span>
        </div>
        <div v-else v-for="chat in Store.chats" :key="chat.id">
              <div v-if="chat.type === 'private'">
                  <div class="d-flex justify-content-start align-items-center chat-spoiler mb-2" style="cursor:pointer" @click="IntoChat(chat)">
                    <div v-for="chatPrivateUser in chat.users" :key="chatPrivateUser">
                      <img v-if="chatPrivateUser.uuid != Store.user.uuid" :src="Store.host + chatPrivateUser.path_image" style="width: 40px; height: 40px; border-radius: 50%;">
                    </div>
                    <div class="d-grid">
                      <div class="d-flex justify-content-between align-items-center">
                        <div>
                          <span class="font-docs ml-2 mr-2">{{ chat.title }}</span>
                          <div v-if="chat.notifications_count >= 1" class="d-flex align-items-center justify-content-center" style="width: 20px; height: 20px; background-color: #0DCAF0; border-radius: 50%">
                            <span class="font-docs text-black"> {{ chat.notifications_count }}</span>
                          </div>
                          <span class="font-docs text-muted text-end" style="font-size: 14px; color:black!important">{{ formatTimer(chat.messages[chat.messages.length - 1].created_at) }}</span>
                        </div>
                      </div>
                      <div v-if="chat.messages[chat.messages.length - 1].user_uuid != Store.user.uuid" class="d-flex">
                        <span class="font-docs ml-2">
                          {{ chat.messages[chat.messages.length - 1].content.length > 40 ? chat.messages[chat.messages.length - 1].content.slice(0, 40) + '...' : chat.messages[chat.messages.length - 1].content }}
                          <i class="fa fa-arrow-left" style="font-size: 13px;  color:blue" aria-hidden="true"></i>
                        </span>
                      </div>

                      <div v-if="chat.messages[chat.messages.length - 1].user_uuid == Store.user.uuid">
                        <span class="font-docs ml-2">
                          {{ chat.messages[chat.messages.length - 1].content.length > 40 ? chat.messages[chat.messages.length - 1].content.slice(0, 40) + '...' : chat.messages[chat.messages.length - 1].content }}
                           <i class="fa fa-arrow-right" style="font-size: 13px; color:green" aria-hidden="true"></i>
                        </span>
                      </div>
                    </div>
                  </div>
               </div>
               <div v-if="chat.type === 'group'">
                  <div class="d-flex justify-content-start align-items-center chat-spoiler mb-2" style="cursor:pointer" @click="IntoChat(chat)">
                    <div>
                      <img src="../../../assets/img/group.png" style="width: 40px; height: 40px; border-radius: 50%;">
                    </div>
                    <div class="d-grid">
                      <div class="d-flex align-items-center">
                        <span class="font-docs ml-2 mr-2">{{ chat.title }}</span>
                        <div v-if="chat.notifications_count >= 1" class="d-flex align-items-center justify-content-center" style="width: 20px; height: 20px; background-color: #0DCAF0; border-radius: 50%">
                          <span class="font-docs text-black"> {{ chat.notifications_count }}</span>
                        </div>
                            <span class="font-docs text-muted text-end" style="font-size: 14px; color:black!important">{{ formatTimer(chat.messages[chat.messages.length - 1].created_at) }}</span>
                        </div>

                      <div v-if="chat.messages.length <= 0">
                      </div>

                      <div v-else-if="chat.messages[chat.messages.length - 1].user_uuid != Store.user.uuid">
                        <span class="font-docs ml-2">
                          {{ chat.messages[chat.messages.length - 1].content.length > 40 ? chat.messages[chat.messages.length - 1].content.slice(0, 40) + '...' : chat.messages[chat.messages.length - 1].content }}
                          <i class="fa fa-arrow-left" style="font-size: 13px;  color:blue" aria-hidden="true"></i>
                        </span>
                      </div>

                      <div v-else-if="chat.messages[chat.messages.length - 1].user_uuid == Store.user.uuid">
                        <span class="font-docs ml-2">
                          {{ chat.messages[chat.messages.length - 1].content.length > 40 ? chat.messages[chat.messages.length - 1].content.slice(0, 40) + '...' : chat.messages[chat.messages.length - 1].content }}
                          <i class="fa fa-arrow-right" style="font-size: 13px; color:green" aria-hidden="true"></i>
                        </span>
                      </div>

                    </div>
                  </div>
               </div>
           </div>
    </div>
</template>
